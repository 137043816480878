#experience_section {
  background-color: var(--background);
}

.experience_end {
  background-color: var(--background);
}

#experience_section .section_title {
  color: var(--white);
  margin-bottom: clamp(50px, 6vw, 80px);
}

#experience_section .job.in_animation {
  opacity: 0;
  transform: translateY(70px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out,
    filter 0.3s ease;
}

#experience_section .experience_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: clamp(20px, 7vw, 100px);
}

@media screen and (max-width: 1125px) {
  #experience_section .experience_content {
    gap: clamp(20px, 5vw, 100px);
  }
}

@media screen and (max-width: 780px) {
  #experience_section .experience_content {
    gap: 35px;
  }
}

#experience_section .job {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: clamp(0px, calc(18vw - 234px), 60px);
}

#experience_section .job.inverted {
  flex-direction: row-reverse;
  padding-left: clamp(0px, calc(18vw - 234px), 60px);
  padding-right: 0px;
}

#experience_section .job_view {
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#experience_section .job_view a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(clamp(400px, 40vw, 600px) * 1.05);
  overflow: hidden;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  transition: filter 0.3s ease;
}

#experience_section .job_view a:hover {
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.7));
}

#experience_section .job_view img {
  width: 100%;
  height: 100%;
}

#experience_section .job_text {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: -100px;
}

#experience_section .inverted .job_text {
  align-items: flex-end;
  margin-left: -100px;
  margin-right: 0px;
}

#experience_section .job_text .type {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 7px;
  font-family: raleway_medium, sans-serif;
  font-size: 16px;
  white-space: nowrap;
  color: var(--light_blue);
  margin-left: 15px;
  margin-bottom: 10px;
}

#experience_section .inverted .job_text .type {
  margin-left: 0px;
  margin-right: 15px;
  flex-direction: row-reverse;
}

#experience_section .job_text .job_title {
  font-family: calibre_semi_bold, sans-serif;
  font-size: 32px;
  color: var(--white);
  text-decoration: none;
  margin-bottom: 12px;
  margin-left: 15px;
  transition: color 0.2s ease;
}

#experience_section .inverted .job_text .job_title {
  margin-left: 0px;
  margin-right: 15px;
}

#experience_section .job_text .job_title:hover {
  color: var(--light_blue);
}

#experience_section .job_text .text {
  background-color: var(--yellow);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 25px 25px 25px 25px;
  margin-bottom: 19px;
  transition: box-shadow 0.3s ease;
}

#experience_section .job_text .text:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.6);
}

#experience_section .job_text .text p {
  font-family: calibre_regular, sans-serif;
  font-size: 20px;
  text-align: start;
  color: var(--fade_white);
}

#experience_section .inverted .job_text .text p {
  text-align: end;
}

#experience_section .job_text .tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 17px;
  margin-left: 9px;
  gap: 8px;
}

#experience_section .inverted .job_text .tags {
  justify-content: flex-end;
  margin-left: 0px;
  margin-right: 9px;
}

#experience_section .job_text .tags a {
  font-family: raleway_semi_bold, sans-serif;
  font-size: 16px;
  color: var(--light_blue);
  text-decoration: none;
  opacity: 1;
  padding: 3px 6px 3px 6px;
  white-space: nowrap;
  transition: opacity 0.2s ease;
}

#experience_section .job_text .tags a:hover {
  opacity: 0.5;
}

#experience_section .job_text .links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 15px;
  gap: 20px;
}

@media screen and (max-width: 780px) {
  #experience_section .job {
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
    transition: filter 0.3s ease;
  }

  #experience_section .job:hover {
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.7));
  }

  #experience_section .job_text {
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding: 60px 40px 60px 40px;
    background-color: rgba(52, 52, 102, 0.75);
    border-radius: 15px;
  }

  @media screen and (max-width: 500px) {
    #experience_section .job_text {
      padding: 50px 25px 50px 25px;
    }
  }

  #experience_section .job_text .type {
    margin-left: 0px;
  }

  #experience_section .job_text .job_title {
    margin-left: 0px;
  }

  #experience_section .job_text .text {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding: 10px 0px 8px 0px;
  }

  #experience_section .job_text .text:hover {
    box-shadow: none;
  }

  #experience_section .job_text .text p {
    color: var(--white);
  }

  #experience_section .job_text .tags {
    margin-left: -6px;
  }

  #experience_section .job_text .links {
    margin-left: 0px;
  }

  #experience_section .job_text .links .bubble {
    bottom: 15px;
  }
}
