#projects_section {
  background-color: var(--background);
}

.projects_end {
  background-color: var(--background);
}

#projects_section .section_title {
  color: var(--white);
  margin-bottom: 60px;
}

#projects_section .project.in_animation {
  opacity: 1;
  transform: translateY(70px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out,
    filter 0.3s ease;
}

#projects_section .sort_choices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 70px;
  gap: 20px;
}

#projects_section .sort_choices .label {
  color: var(--white);
  font-family: raleway_semi_bold, sans-serif;
  font-size: 17px;
}

#projects_section .sort_choices .choice {
  color: var(--light_blue);
  font-family: raleway_semi_bold, sans-serif;
  font-size: 15px;
  cursor: pointer;
  border-radius: 100px;
  padding: 9px 18px 9px 18px;
  border: 2px solid var(--light_blue);
  transition: background-color 0.2s ease-in-out;
}

#projects_section .sort_choices .choice:hover {
  background-color: var(--fade_light_blue);
}

#projects_section .sort_choices .choice.selected {
  color: var(--background);
  background-color: var(--light_blue);
  cursor: default;
  pointer-events: none;
}

#projects_section .sort_choices .choice.selected:hover {
  background-color: var(--light_blue);
}

#projects_section .projects_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: clamp(20px, 7vw, 100px);
  margin-bottom: clamp(60px, 8vw, 100px);
}

@media screen and (max-width: 1125px) {
  #projects_section .projects_content {
    gap: clamp(20px, 5vw, 100px);
  }
}

@media screen and (max-width: 780px) {
  #projects_section .projects_content {
    gap: 35px;
  }
}

#projects_section .project {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: clamp(0px, calc(18vw - 234px), 60px);
}

#projects_section .project.inverted {
  flex-direction: row-reverse;
  padding-left: clamp(0px, calc(18vw - 234px), 60px);
  padding-right: 0px;
}

#projects_section .project_view {
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#projects_section .project_view a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(clamp(400px, 40vw, 600px) * 1.05);
  overflow: hidden;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
  transition: filter 0.3s ease;
}

#projects_section .project_view a:hover {
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.7));
}

#projects_section .project_view img {
  width: 100%;
  height: 100%;
}

#projects_section .project_text {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: -100px;
}

#projects_section .other_project_text .other_type {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 7px;
  font-family: raleway_medium, sans-serif;
  font-size: 16px;
  white-space: nowrap;
  color: var(--light_blue);
}

#projects_section .inverted .project_text {
  align-items: flex-end;
  margin-left: -100px;
  margin-right: 0px;
}

#projects_section .project_text .type {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 7px;
  font-family: raleway_medium, sans-serif;
  font-size: 16px;
  white-space: nowrap;
  color: var(--light_blue);
  margin-left: 15px;
  margin-bottom: 10px;
}

#projects_section .inverted .project_text .type {
  margin-left: 0px;
  margin-right: 15px;
  flex-direction: row-reverse;
}

#projects_section .project_text .project_title {
  font-family: calibre_semi_bold, sans-serif;
  font-size: 32px;
  color: var(--white);
  text-decoration: none;
  margin-bottom: 12px;
  margin-left: 15px;
  transition: color 0.2s ease;
}

#projects_section .inverted .project_text .project_title {
  margin-left: 0px;
  margin-right: 15px;
}

#projects_section .project_text .project_title:hover {
  color: var(--light_blue);
}

#projects_section .project_text .text {
  background-color: var(--yellow);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 25px 25px 25px 25px;
  margin-bottom: 19px;
  transition: box-shadow 0.3s ease;
}

#projects_section .project_text .text:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.6);
}

#projects_section .project_text .text p {
  font-family: calibre_regular, sans-serif;
  font-size: 20px;
  text-align: start;
  color: var(--fade_white);
}

#projects_section .inverted .project_text .text p {
  text-align: end;
}

#projects_section .project_text .tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 17px;
  margin-left: 9px;
  gap: 8px;
}

#projects_section .inverted .project_text .tags {
  justify-content: flex-end;
  margin-left: 0px;
  margin-right: 9px;
}

#projects_section .project_text .tags a {
  font-family: raleway_semi_bold, sans-serif;
  font-size: 16px;
  color: var(--light_blue);
  text-decoration: none;
  opacity: 1;
  padding: 3px 6px 3px 6px;
  white-space: nowrap;
  transition: opacity 0.2s ease;
}

#projects_section .project_text .tags a:hover {
  opacity: 0.5;
}

#projects_section .project_text .links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 15px;
  gap: 20px;
}

#projects_section .inverted .project_text .links {
  margin-left: 0px;
  margin-right: 15px;
}

#projects_section .project_text .links .github {
  --color: rgb(181, 184, 192);
}

#projects_section .project_text .links .test {
  --color: rgb(41, 168, 99);
}

#projects_section .project_text .links svg {
  width: 21px;
  fill: none;
  stroke-width: 1.9px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--color);
  opacity: 1;
  transition: opacity 0.2s ease;
}

#projects_section .project_text .links svg:hover {
  opacity: 0.6;
}

#projects_section .project_text .links a {
  display: flex;
  justify-content: center;
  align-items: center;
}

#projects_section .project_text .links .bubble {
  pointer-events: none;
  position: absolute;
  text-align: center;
  bottom: -45px;
  color: var(--background);
  padding: 6px 12px 6px 12px;
  background-color: var(--color);
  font-family: raleway_bold, sans-serif;
  white-space: nowrap;
  font-size: 15px;
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#projects_section .project_text .links .bubble::after {
  pointer-events: none;
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent var(--color) transparent;
}

#projects_section .project_text .links a:hover .bubble {
  opacity: 0.8;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 120px;
  height: 120px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 96px;
  height: 96px;
  margin: 12px;
  border: 12px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 780px) {
  #projects_section .project {
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
    transition: filter 0.3s ease;
  }

  #projects_section .project:hover {
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.7));
  }

  #projects_section .project_text {
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding: 60px 40px 60px 40px;
    background-color: rgba(52, 52, 102, 0.75);
    border-radius: 15px;
  }

  @media screen and (max-width: 500px) {
    #projects_section .project_text {
      padding: 50px 25px 50px 25px;
    }
  }

  #projects_section .project_text .type {
    margin-left: 0px;
  }

  #projects_section .project_text .project_title {
    margin-left: 0px;
  }

  #projects_section .project_text .text {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding: 10px 0px 8px 0px;
  }

  #projects_section .project_text .text:hover {
    box-shadow: none;
  }

  #projects_section .project_text .text p {
    color: var(--white);
  }

  #projects_section .project_text .tags {
    margin-left: -6px;
  }

  #projects_section .project_text .links {
    margin-left: 0px;
  }

  #projects_section .project_text .links .bubble {
    bottom: 15px;
  }
}
