#skills_section {
  background-color: var(--white);
}

.skills_end {
  background-color: var(--white);
}

#skills_section .section_title {
  color: var(--black_title);
}

#skills_section .skills_content {
  display: flex;
  width: 100%;
}

#skills_section .box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  filter: drop-shadow(0px 0px 10px rgba(28, 28, 51, 0.15));
}

#skills_section .box .menu {
  height: 100%;
  max-height: 575px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#skills_section .box .menu .selector {
  z-index: 2;
  width: 320px;
  transition: 0.2s;
  position: absolute;
  margin-right: -40px;
  border-radius: 20px 0 0 20px;
  background-color: white;
}

#skills_section .box .menu .category {
  width: 280px;
  z-index: 3;
  border-radius: 20px;
  white-space: nowrap;
  padding: 30px 40px 30px 0px;
  margin-right: -40px;
  font-family: proxima_nova_semi_bold, sans-serif;
  font-size: 16px;
  color: var(--blue);
  text-align: center;
  cursor: pointer;
}

#skills_section .box .box_content {
  width: 100%;
  min-height: 475px;
  border-radius: 20px;
  background-color: white;
  padding: 50px 60px 50px 60px;
}

@-webkit-keyframes enter_anim {
  from {
    transform: translateY(25px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes enter_anim {
  from {
    transform: translateY(25px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

#skills_section .box .box_content .skills_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 50px;
  opacity: 0;
  transform: translateY(25px);
  -webkit-animation: enter_anim 0.5s ease 1 forwards;
  animation: enter_anim 0.5s ease 1 forwards;
}

#skills_section .box .box_content .skills_list .skill {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  text-decoration: none;
}

#skills_section .box .box_content .skills_list .skill img {
  width: 95px;
  height: 95px;
  transform: scale(0.88);
  transition: transform 0.3s ease-in-out;
}

#skills_section .box .box_content .skills_list .skill:hover img {
  transform: scale(1);
}

#skills_section .box .box_content .skills_list .skill span {
  font-family: proxima_nova_semi_bold, sans-serif;
  font-size: 16px;
  color: var(--black_text);
  text-align: center;
}

#skills_section .box .category_title {
  white-space: nowrap;
  margin: 0px 0px -50px 0px;
  font-family: proxima_nova_semi_bold, sans-serif;
  font-size: 22px;
  color: var(--blue);
  margin-bottom: -40px;
}

@media screen and (max-width: 930px) {
  #skills_section .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    min-height: 0px;
  }

  #skills_section .box .box_content {
    min-height: 0px;
    width: calc(100% - 100px);
    padding: 50px 50px 50px 50px;
    min-width: 230px;
  }

  #skills_section .box .box_content .skills_list {
    grid-gap: 25px;
  }

  @media screen and (max-width: 565px) {
    #skills_section .box .box_content .skills_list {
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 500px) {
    #skills_section .box .box_content .skills_list {
      justify-content: space-between;
    }

    #skills_section .box .box_content {
      width: calc(100% - 50px);
      padding: 40px 25px 40px 25px;
    }
  }

  @media screen and (max-width: 465px) {
    #skills_section .box .box_content .skills_list {
      justify-content: space-around;
    }
  }
}
