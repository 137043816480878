#contact_section {
  background-color: var(--white);
}

#contact_section .content.leaning {
  margin-top: calc(calc(clamp(110px, 7vw, 130px) + 3.3vw) * 1.65);
  margin-bottom: calc(clamp(100px, 7vw, 130px) * 2);
}

#contact_section .section_title {
  color: var(--black_title);
  margin-bottom: 70px;
}

#contact_section .contact_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 780px) {
  #contact_section .contact_content {
    flex-direction: column;
  }
}

#contact_section .social_line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

#contact_section .social_link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 10px 25px 25px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(28, 28, 51, 0.2);
  gap: 15px;
  text-decoration: none;
  transition: padding 0.5s ease-in-out;
}

#contact_section .social_link:hover {
  padding-right: 25px;
}

#contact_section .social_link img {
  width: 50px;
}

#contact_section .social_link .social_text {
  overflow: hidden;
  width: 0px;
  transition: width 0.5s ease-in-out;
}

#contact_section .social_link:hover .social_text {
  width: var(--size);
}

#contact_section .social_link .social_text span {
  font-family: proxima_nova_semi_bold, sans-serif;
  font-size: 20px;
  color: var(--black_text);
  white-space: nowrap;
}

#contact_section .social_link .social_text span a {
  text-decoration: none;
  color: var(--black_text);
}

@media screen and (max-width: 500px) {
  #contact_section .social_link:hover {
    padding-right: 10px;
  }

  #contact_section .social_link:hover .social_text {
    width: 0px;
  }
}
