#footer_section {
  background-color: var(--background);
}

#footer_section .content {
  margin-top: clamp(90px, 6vw, 110px);
  margin-bottom: calc(clamp(90px, 6vw, 110px) * 0.75);
  gap: 80px;
}

#footer_section .footer_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#footer_section .footer_content:nth-child(2) {
  justify-content: center;
}

#footer_section .footer_content a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  padding: 10px 20px 10px 20px;
  transition: opacity 0.2s ease-in-out;
}

#footer_section .footer_content a:hover {
  opacity: 0.5;
}

#footer_section .footer_content span,
#footer_section .footer_content p {
  font-family: proxima_nova_semi_bold, sans-serif;
  font-size: 15px;
  color: white;
  text-align: center;
}

#footer_section .footer_content span {
  white-space: nowrap;
}

#footer_section .footer_content img {
  width: 20px;
  margin-bottom: 5px;
  margin-left: 8px;
  margin-right: 8px;
}

#footer_section .footer_content svg {
  width: 18px;
  margin-bottom: 5px;
  margin-left: 6px;
  fill: none;
  stroke-width: 2.1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: white;
}

#footer_section .footer_content:nth-child(2) p {
  max-width: 500px;
  line-height: 1.3;
  padding: 10px 20px 10px 20px;
}

@media screen and (max-width: 730px) {
  #footer_section .content {
    gap: 50px;
  }

  #footer_section .footer_content {
    flex-direction: column;
    gap: 50px;
  }
}
