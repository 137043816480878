#header_div {
  display: flex;
  position: fixed;
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  box-shadow: none;
  transition: height 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    top 0.5s ease-in-out;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 29, 48, 0);
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  transition: background-color 0.4s ease-in-out,
    backdrop-filter 0.4s ease-in-out, -webkit-backdrop-filter 0.4s ease-in-out;
}

#header .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#header .logo {
  display: flex;
}

#header .logo a {
  display: flex;
  pointer-events: all;
  width: 35px;
  height: 35px;
}

#header .logo img {
  display: flex;
}

#header .menu {
  display: flex;
}

#header .menu ol {
  display: flex;
}

#header .menu li {
  display: flex;
  margin: 0px 10px;
}

#header .menu a {
  display: flex;
  pointer-events: all;
  padding: 10px 15px;
  font-family: raleway_semi_bold, sans-serif;
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
}

#header .menu .not_button {
  transition: transform 0.2s ease, color 0.2s ease;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.2;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

#header .menu .not_button:hover {
  transform: translateY(-2px);
}

#header .menu .button {
  min-width: 160px;
  color: var(--white);
  background-color: var(--blue);
  padding: 17px 0px 17px 0px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 900;
  border-radius: 7px;
  letter-spacing: 0.26px;
}

#header .menu .button:hover {
  background-color: var(--light_blue);
}

#header .menu .button .button_arrow {
  height: 12px;
  margin-left: 12px;
  transform: rotate(-90deg);
}

#header .menu_icon {
  display: block;
  pointer-events: all;
  padding: 20px 0px 20px 20px;
  margin-right: -20px;
  cursor: pointer;
  stroke: var(--blue);
  stroke-width: 10px;
  stroke-linecap: round;
  stroke-linejoin: round;
  height: 30px;
  transition: stroke 0.2s ease;
}

#header .menu_icon:hover {
  stroke: var(--blue);
}

#header .in_animation {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}
