@font-face {
  font-family: raleway_bold;
  src: url("../../public/fonts/raleway/Raleway-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway_semi_bold;
  src: url("../../public/fonts/raleway/Raleway-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: raleway_medium;
  src: url("../../public/fonts/raleway/Raleway-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: calibre_semi_bold;
  src: url("../../public/fonts/calibre/CalibreSemibold.otf");
  font-display: swap;
}

@font-face {
  font-family: calibre_medium;
  src: url("../../public/fonts/calibre/CalibreMedium.otf");
  font-display: swap;
}

@font-face {
  font-family: calibre_regular;
  src: url("../../public/fonts/calibre/CalibreRegular.otf");
  font-display: swap;
}

@font-face {
  font-family: calibre_light;
  src: url("../../public/fonts/calibre/CalibreLight.otf");
  font-display: swap;
}

@font-face {
  font-family: proxima_nova_bold;
  src: url("../../public/fonts/proxima_nova/ProximaNovaBold.otf");
  font-display: swap;
}

@font-face {
  font-family: proxima_nova_semi_bold;
  src: url("../../public/fonts/proxima_nova/ProximaNovaSemibold.otf");
  font-display: swap;
}

body {
  --light_blue: #33a7dc;
  --blue: #0091d3;
  --fade_light_blue: rgba(86, 220, 252, 0.15);
  --light_gray: #8ea9bf;
  --white: #f6f9fc;
  --fade_white: #1f485e;
  --dark_blue: #101d30;
  --navbar_color: #ffffff;
  --black_title: #2b3954;
  --black_text: #425466;
  --background: #22485f;
  --yellow: #fdfd89;
}
